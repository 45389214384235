
import { Component, Prop, Vue } from "vue-property-decorator";
import { appId } from "@/../capacitor.config.json";

type Service = {
  id: number;
  attributes: Array<object>;
};

@Component({
  components: {
    quantity_: () => import("./elements/Quantity.vue"),
    datepicker_: () => import("./elements/DatePicker.vue"),
    timepicker_: () => import("./elements/TimePicker.vue"),
    radiogroup_: () => import("./elements/RadioGroup.vue"),
    checkboxgroup_: () => import("./elements/CheckboxGroup.vue"),
    textcontent_: () => import("./elements/TextContent.vue"),
    input_: () => import("./elements/Input.vue"),
    select_: () => import("./elements/Select.vue"),
    textarea_: () => import("./elements/Textarea.vue")
  }
})
export default class FormBuilder extends Vue {
  @Prop(Object) readonly service!: Service;

  valid: boolean = true as boolean;
  data = {};

  created() {
    this.service.attributes.forEach(el => {
      this.data = {
        ...this.data,
        [el["id"]]: null
      };
    });
  }

  order() {
    const formData = {
      bundle: appId,
      service: this.service.id,
      formData: this.data
    };

    this.$store.dispatch("service/order", formData).then(({ data }) => {
      this.$router.push({ name: "order", params: { id: data.id } });
    });
  }
}
